export default {
  async asyncData(context) {
    let store = context.store;
    const params = context.params;

    await store.dispatch('branch/fetchBranchList'); // Get all branch lists

    const branches = store.getters['branch/getBranchList'];
    if (!branches) {
      context.error({
        statusCode: 404,
        message: 'Branch Data not found',
      });

      return;
    }

    const branchId = store.getters['branch/getIdBySlug'](params.id);
    let hasBranch = false;
    if (branchId) hasBranch = branches[branchId] ?? undefined;
    if (params.id && !hasBranch) {
      const fetchedId = await store.dispatch('branch/fetchBranch', {
        slug: params.id,
      });

      const updatedBranch = store.getters['branch/getById'](fetchedId);
      if (!updatedBranch) {
        context.error({
          statusCode: 404,
          message: 'Branch not found',
        });
        return;
      }
    }

    return;
  },
};
