import { render, staticRenderFns } from "./_.vue?vue&type=template&id=f25d100e&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyServiceCms: function () { return import('/home/node/frontend/components/pages/ServiceCms.vue' /* webpackChunkName: "components/pages/ServiceCms" */).then(function(m) { return m['default'] || m }) },LazyCms: function () { return import('/home/node/frontend/components/pages/Cms.vue' /* webpackChunkName: "components/pages/Cms" */).then(function(m) { return m['default'] || m }) },LazyNewBlogPage: function () { return import('/home/node/frontend/components/cms-modules/NewBlogPage.vue' /* webpackChunkName: "components/cms-modules/NewBlogPage" */).then(function(m) { return m['default'] || m }) },LazyBulletinList: function () { return import('/home/node/frontend/components/bulletin/BulletinList.vue' /* webpackChunkName: "components/bulletin/BulletinList" */).then(function(m) { return m['default'] || m }) },LazyArchiveBlog: function () { return import('/home/node/frontend/components/pages/ArchiveBlog.vue' /* webpackChunkName: "components/pages/ArchiveBlog" */).then(function(m) { return m['default'] || m }) },LazySingleBlog: function () { return import('/home/node/frontend/components/pages/SingleBlog.vue' /* webpackChunkName: "components/pages/SingleBlog" */).then(function(m) { return m['default'] || m }) },Fourofour: require('/home/node/frontend/components/pages/Fourofour.vue').default})
