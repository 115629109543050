// Sorts by order first else defaults to sort by alphabet.
const sortNumberOrdering = (a, b) => {
  if (a.order === null) {
    return a.name.localeCompare(b.name);
  } else if (b.order === null) {
    return -1;
  }
  return a.order - b.order;
};

export default {
  async asyncData(context) {
    let store = context.store;
    let axios = context.$axios;

    if (
      Object.keys(store.state.category.treeStructure).length !== 0 &&
      Object.keys(store.state.category.categories).length !== 0
    ) {
      return;
    }

    let response
    try {
      response = await axios.get('/product-categories');
    } catch (e) {
      console.error(`[ api ][ categories ][ asyncData ] Error while getting product-categories, ${e.message}`);
      console.error(e)
      return;
    }

    let rawCategories = response.data.data;
    let tree = [];
    let categories = {};

    // Sorting parent categories
    rawCategories.forEach(category => {
      categories[category.id] = category;
      if (!category.parentId) {
        tree.push({
          ...category,
          items: [],
        });
      }
    });
    tree.sort(sortNumberOrdering);

    rawCategories.forEach(category => {
      if (category.parentId) {
        const parent = tree.find(cat => cat.id === category.parentId);
        if (parent) {
          // find if the current category has any children
          category.items = [];
          rawCategories.forEach(potentialChildCategory => {
            // if it does, we look for further child and push the child to it
            if (potentialChildCategory.parentId === category.id) {
              potentialChildCategory.items = [];

              // For each child, we find if they have a child
              rawCategories.forEach(child => {
                // If they have a child, we add it to the upper child's item list
                if (child.parentId === potentialChildCategory.id) {
                  potentialChildCategory.items.push(child);
                  potentialChildCategory.items.sort(sortNumberOrdering);
                }
              });

              // Pushing the upper child with childs items list into parent.
              category.items.push(potentialChildCategory);
              category.items.sort(sortNumberOrdering);
            }
          });

          parent.items.push(category);
          parent.items.sort(sortNumberOrdering);
        }
      }
    });

    store.commit('category/saveCategories', {
      categories: categories,
      tree: tree,
    });
  },
};
