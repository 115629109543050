const getMedia = async (store, data) => {
  let media = store.getters['media/getMedia', data];

  if (media === undefined) {
    return await store.dispatch('media/getMedia', data);
  }

  return media;
};

const getSeoMetaData = async (store, data) => {
  let seo = {...data};
  if (data.open_graph_images) {
    let media = await getMedia(store, {
      source: data.source ? data.source : 'cms',
      id: data.open_graph_images,
      format: 'default',
    });

    if (media) {
      seo.open_graph_url = media.default;
    }
  }

  return seo;
};

export default {
  async asyncData(context) {
    const store = context.store;
    const route = context.route;

    let pageData = store.getters['sitemap/getPage'](route.path);
    let temppageData = {...pageData};

    if (temppageData && temppageData.seo) {
      temppageData.seo = await getSeoMetaData(store, temppageData.seo);
      store.commit('sitemap/add', temppageData);
    }

  },
};
