export default {
  async asyncData(context) {
    let store = context.store;
    let axios = context.$axios;

    if (Object.keys(store.state.settings.cms).length <= 0) {
      const cmsSettingsCall = await axios.get('/cms/general/v1/settings');
      if (cmsSettingsCall.data) {
        store.commit('settings/setCmsSettings', cmsSettingsCall.data);
      }
    }

    if (Object.keys(store.state.settings.uom).length <= 0) {
      const uomSettingsCall = await axios.get('/products-erp/settings/uom');
      if (uomSettingsCall.data) {
        store.commit('settings/setUomSettings', uomSettingsCall.data);
      }
    }

    return;
  },
};
