//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SEOMixins from '~/mixins/seo';

import sitemap from '~/api/sitemap';
import seo from '~/api/seo';
import branch from '~/api/branch';
import categories from '~/api/categories';
import settings from '~/api/settings';
import productsMeta from '~/api/productsMeta';

import { mapActions, mapGetters } from 'vuex';

export default {
  async asyncData(context) {
    if (!(await sitemap.asyncData(context))) return;

    let path = context.route.path;
    if (path.charAt(path.length - 1) !== '/') path += '/';
    let sitePath = context.store.getters['sitemap/getPage'](path);

    // Have to check sitemap store -  If no type exists it is a 404 page and will default to error.vue layout
    if (!sitePath || !sitePath?.type || sitePath?.type === 'fourofour') {
      context.error({
        statusCode: 404,
        message: 'Page not found',
      });
    }

    await seo.asyncData(context);

    return Promise.all([
      branch.asyncData(context),
      settings.asyncData(context),
      categories.asyncData(context),
      sitemap.asyncData(context),
      productsMeta.asyncData(context),
    ]);
  },
  data() {
    return {
      hasFetchedMedia: false,
    };
  },
  mixins: [SEOMixins],
  methods: {
    ...mapActions({
      fetchMedia: 'media/fetchMedia',
    }),
    async getSeoMedia() {
      if (this.current && this.current.seo && this.current.seo.social_image) {
        await this.fetchMedia({
          source: 'cms',
          id: this.current.seo.social_image,
        });
      }
    },
    generateOrganisationSchema() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: process.env.BASE_URL,
        name: 'Bowens',
        alternateName: ['Bowens Timber and Hardware', 'The Builders Choice'],
        logo: [`${process.env.BASE_URL}/svg/Bowens_logo.svg`],
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '+61-03-9796-3088',
            contactType: 'support office',
            areaServed: 'AU',
          },
        ],
        address: {
          addressCountry: 'Australia',
          postalCode: '3803',
          streetAddress: '48-50 Hallam S Rd, Hallam VIC 3803',
          addressLocality: 'Melbourne, Australia',
        },
      };
    },
    generateWebsiteSchema() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Website',
        url: process.env.BASE_URL,
        name: 'Bowens',
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${process.env.BASE_URL}/search/?query={search_term_string}`,
          },
          query: 'required',
          'query-input': 'required name=search_term_string',
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      getMedia: 'media/getMedia',
    }),
    isServiceCmsPage() {
      return (
        this.current &&
        this.current.post_type === 'page' &&
        this.current.is_services_layout_enabled === true
      );
    },

    isNewBlogPage() {
      if (this.current && this.current.modules) {
        const module = this.current.modules;

        for (let i = 0; i < module.length; i++) {
          if (module[i].module_type === 'NewBlogPage') {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    seoSocialImage() {
      if (this.current && this.current.seo && this.current.seo.social_image) {
        return this.getMedia({
          source: 'cms',
          id: this.current.seo.social_image,
        });
      }
    },
    current() {
      let path = this.$route.path;
      if (path.charAt(path.length - 1) !== '/') path += '/';
      return this.$store.getters['sitemap/getPage'](path);
    },
    seo() {
      let seo = {};
      // Blog Home Page has type === 'archive-blog'
      if (this.current?.type === 'archive-blog') {
        seo.title = this.$t('blog.title');
        seo.description = this.$t('blog.description');
        seo.isBlogHome = true;
      } else if (this.current?.type === 'single-blog') {
        // Single Blog Page
        seo.title = this.current?.seo?.title || this.current?.title;
        seo.description = this.current?.seo?.description;
        seo.isSingleBlogPost = true;
      } else if (this.current?.type === 'category-blog') {
        // Single Blog Category Page
        const activeCatId = this.current.data?.active_cat;
        const activeCat = this.current.data?.cats?.[activeCatId];
        seo.title = activeCat.title;
        seo.description = activeCat.description;
        seo.isBlogCategoryPage = true;
      } else {
        seo.title = this.current?.seo?.title;
        seo.description = this.current?.seo?.description;
      }
      seo.titleFallback = this.current?.title;

      seo.canonical = this.current?.seo?.canonical;
      seo.robots = this.current?.seo?.robots;
      if (this.seoSocialImage?.default) {
        seo.image = this.seoSocialImage.default;
      }

      if (this.current.url === '/') {
        seo.schema = {
          organisation: this.generateOrganisationSchema(),
          website: this.generateWebsiteSchema(),
        };
      }

      seo.isBlog = this.current?.post_type === 'post';

      return seo;
    },
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  async mounted() {
    await this.$recaptcha.init();
  },
  async fetch() {
    await this.getSeoMedia();
  },
  fetchOnServer: true,
};
