const shouldFetchFromAPI = (cacheTime, page, dayjs) => {
  return (
    page === null ||
    (page !== undefined &&
      dayjs().diff(dayjs(page.time_cache), 'second') > parseInt(cacheTime))
  );
};

const archiveBlogPageData = async function(axios, store, data) {
  const api_response = await axios.get(`/cms/blog/v1/posts/${data.id}`);
  let sitemapData = api_response.data;

  store.commit('blog/setCurrentPaginations', {
    pages: sitemapData.pages,
    current_page: sitemapData.current_page,
  });

  store.commit('blog/addToBlogList', {
    page: sitemapData.current_page,
    posts: sitemapData.posts,
  });

  let blogs = [];

  sitemapData.posts.forEach(id => {
    blogs.push(
      store.dispatch('blog/getBlogFromStore', {
        id: id,
        page: sitemapData.current_page,
      })
    );
  });

  await Promise.all(blogs);

  let cats_data = await store.dispatch('blog/fetchBlogCategoriesFromStore');

  sitemapData.data = {
    blogs: blogs,
    cat: cats_data,
  };

  return sitemapData;
};

const categoryBlogPageData = async function(axios, store, data) {
  const api_response = await axios.get(
    `/cms/blog/v1/category/${data.id}/${data.page}`
  );
  let sitemapData = api_response.data;
  store.commit('blog/setCurrentPaginations', {
    pages: sitemapData.pages,
    current_page: sitemapData.current_page,
  });
  store.commit('blog/addToBlogsByCategory', {
    cat_id: data.id,
    page: sitemapData.current_page,
    posts: sitemapData.posts,
  });
  let blogsByCat = [];

  sitemapData.posts.forEach(id => {
    blogsByCat.push(
      store.dispatch('blog/getBlogFromStore', {
        id: id,
        page: sitemapData.current_page,
        cat: data.id,
      })
    );
  });

  await Promise.all(blogsByCat);

  let cats_data = await store.dispatch('blog/fetchBlogCategoriesFromStore');

  sitemapData.data = {
    blogs: blogsByCat,
    cats: cats_data,
    active_cat: data.id,
  };

  return sitemapData;
};

const singleBlogPageData = async function(axios, store, data) {
  const api_response = await store.dispatch('blog/getBlogFromStore', {
    id: data.id,
  });
  let sitemapData = api_response || {};
  sitemapData.cats = await store.dispatch('blog/fetchBlogCategoriesFromStore');
  return sitemapData;
};

const resolveSitemapData = async function(axios, store, data) {
  let sitemapData = {};
  let api_response = null;
  switch (data.type) {
    case 'cms':
      if (data.subType === 'bulletin') {
        api_response = await axios.get(`/cms/bulletin/v1/${data.id}`);
      } else {
        api_response = await axios.get(`/cms/post/v1/${data.id}`);
      }
      sitemapData = api_response.data;
      break;
    case 'bulletin-list':
      api_response = await axios.get(`/cms/bulletin/v1/list/${data.id}`);
      sitemapData = {};
      sitemapData.data = api_response.data;
      break;
    case 'archive-blog':
      sitemapData = await archiveBlogPageData(axios, store, data);
      break;
    case 'category-blog':
      sitemapData = await categoryBlogPageData(axios, store, data);
      break;
    case 'single-blog':
      sitemapData = await singleBlogPageData(axios, store, data);
      break;
    case 'product-category':
      sitemapData = store.dispatch('category/getCategory', {
        id: data.id,
      });
      break;
  }

  return sitemapData;
};

/* eslint-disable */
export default {
  async asyncData(context) {
    const store = context.store;
    const axios = context.$axios;
    const dayjs = context.$dayjs;
    const route = context.route;
    const redirect = context.redirect;

    let sitemapPath = route.path;
    if (sitemapPath.charAt(sitemapPath.length - 1) !== '/') sitemapPath += '/';

    const page = store.getters['sitemap/getPage'](sitemapPath);
    if (page) return false;

    if (shouldFetchFromAPI(context.$config.cacheTime, page, dayjs)) {
      const url = '/sitemap' + sitemapPath;
      const sitemap = await axios.get(url);

      let data = sitemap.data;

      if (data.code === 301 || data.code === 302) {
        return redirect(data.code, data.target);
      }

      if (!data.path) {
        data.path = sitemapPath;
      }

      if (data.type === '404') {
        data.type = 'fourofour';
      } else if (data.type === 'cms' && data.subType === 'post-list') {
        data.type = 'archive-blog';
      } else if (data.type === 'cms' && data.subType === 'post') {
        data.type = 'single-blog';
      } else if (data.type === 'cms' && data.subType === 'bulletin-list') {
        data.type = 'bulletin-list';
      } else if (data.type === 'cms' && data.subType === 'category') {
        data.type = 'category-blog';
      }

      const resolveData = await resolveSitemapData(axios, store, data); // Resolve sitemap data
      let sitemapData = { ...resolveData }; // avoid mutating values by its reference error

      if (data.type === 'product-category') {
        sitemapData.id = data.id;
      }

      sitemapData.time_cache = dayjs();
      sitemapData.type = data.type;
      sitemapData.url = sitemapPath;

      store.commit('sitemap/add', sitemapData);
    }

    return true;
  },
};
/* eslint-ensable */
